/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intlOriginal from '@illumio-shared/utils/intl';
import {createSelector} from 'reselect';
import type {LangKeys} from '@illumio-shared/utils/intl/langs';

interface Events<T> {
  value: string;
  label: T;
}

interface EventGeneratedBy {
  server?: {hostname: string};
  user?: {username: string};
  system?: string;
  console?: string;
  ven?: {hostname: string};
  agent?: {hostname: string};
  service_account?: {name: string};
  container_cluster?: {name: string};
}

// Caching intl using intlCache <Map> in order to execute intl only once per key by doing intlOriginal(key).
// Otherwise intl would get executed 1000s of times during page render since we aren't assigning createSelector for EventTypes
//and NotificationTypes variables instead directly assigning the array.
// Reason being that avenger script for events breaks as it is looking for start([) and end braces(]) [] during parsing, immediately
// after checking "export const EventType =". (Hard coded script from backend breaking avenger builds)
const intlCache = new Map<string, string>();
const intl = (key: LangKeys) => (): string => {
  let value = intlCache.get(key);

  if (value === undefined) {
    value = intlOriginal(key);
    intlCache.set(key, value);
  }

  return value;
};

export const EventTypes = [
  {value: 'access_restriction.create', label: intl('EventUtils.AccessRestrictionCreate')},
  {value: 'access_restriction.delete', label: intl('EventUtils.AccessRestrictionDelete')},
  {value: 'access_restriction.update', label: intl('EventUtils.AccessRestrictionUpdate')},
  {value: 'agent.activate', label: intl('EventUtils.VENPaired')},
  {value: 'agent.activate_clone', label: intl('EventUtils.VENCloneActivated')},
  {value: 'agent.clone_detected', label: intl('EventUtils.VENCloneDetected')},
  {value: 'agent.deactivate', label: intl('EventUtils.VENUnpaired')},
  {value: 'agent.fw_state_table_threshold_exceeded', label: intl('EventUtils.VENFirewallStateTableThresholdExceeded')},
  {value: 'agent.goodbye', label: intl('EventUtils.VENGoodbye')},
  {value: 'agent.machine_identifier', label: intl('EventUtils.VENMachineIdentifiers')},
  {value: 'agent.missing_heartbeats_after_upgrade', label: intl('EventUtils.VENMissingHeartbeatAfterUpgrade')},
  {value: 'agent.refresh_token', label: intl('EventUtils.VENRefreshToken')},
  {value: 'agent.request_policy', label: intl('EventUtils.VENFetchedPolicy')},
  {value: 'agent.generate_maintenance_token', label: intl('EventUtils.VENMaintenanceTokenGenerated')},
  {value: 'agent.weak_tls_ciphers_used', label: intl('EventUtils.VENWeakTLSCipherDetected')},
  {value: 'agent.authentication_recovery', label: intl('EventUtils.VENAuthenticationMode')},
  {value: 'agent.rotate_auth_secret', label: intl('EventUtils.VENRotateAuthSecret')},
  {value: 'agent.self_signed_certificate_expired', label: intl('EventUtils.VENSelfSignedExpired')},
  {value: 'agent.self_signed_certificate_in_grace_period', label: intl('EventUtils.VENSelfSignedGracePeriod')},
  {value: 'agent.unauthenticated_clone_detected', label: intl('EventUtils.VENUnauthenticatedClone')},
  {value: 'agents.authentication_recovery', label: intl('EventUtils.VENsAuthenticationMode')},

  {value: 'agent.request_upgrade', label: intl('EventUtils.VENRequestUpgrade')},
  {value: 'agent.upgrade_successful', label: intl('EventUtils.VENUpgradeSuccessful')},
  {value: 'agent.upgrade_time_out', label: intl('EventUtils.VENUpgradeTimeOut')},
  {value: 'agents.clear_conditions', label: intl('EventUtils.VENClearConditions')},
  {value: 'ven_software_releases.set_default_version', label: intl('EventUtils.VENSetDefaultVersion')},
  {value: 'lost_agent.found', label: intl('EventUtils.LostAgentFound')},

  {value: 'agent.service_not_available', label: intl('EventUtils.VENServiceUnavailable')},
  {value: 'agent.suspend', label: intl('EventUtils.VENSuspended')},
  {value: 'agent.tampering', label: intl('EventUtils.VENTampering')},
  {value: 'agent.unsuspend', label: intl('EventUtils.VENUnsuspended')},
  {value: 'agent.update_interactive_users', label: intl('EventUtils.VENUpdateInteractiveUsers')},
  {value: 'agent.update_iptables_href', label: intl('EventUtils.VENUpdateIpTables')},
  {value: 'agent.update_running_containers', label: intl('EventUtils.VENUpdateRunningContainers')},
  {value: 'agent.update', label: intl('EventUtils.VENPropertiesUpdated')},
  {value: 'agent.upload_existing_ip_table_rules', label: intl('EventUtils.VENUploadedIptablessRules')},
  {value: 'agent.generate_clas_token', label: intl('EventUtils.VENClasAuthTokenRequested')},
  {value: 'agent_compatibility_check_report.update', label: intl('EventUtils.VENCompatibilityCheckReportUpdate')},
  {value: 'support_bundle_request.create', label: intl('EventUtils.PCESupportReportRequestCreate')},
  {value: 'support_bundle_request.delete', label: intl('EventUtils.PCESupportReportRequestDelete')},
  {value: 'support_report_request.create', label: intl('EventUtils.SupportReportRequestCreate')},
  {value: 'support_report_request.delete', label: intl('EventUtils.SupportReportRequestDelete')},
  {value: 'support_report.upload', label: intl('EventUtils.SupportReportUpload')},
  {value: 'agents.unpair', label: intl('EventUtils.VENsUnpaired')},
  {value: 'api_key.create', label: intl('EventUtils.APIKeyCreated')},
  {value: 'api_key.delete', label: intl('EventUtils.APIKeyDeleted')},
  {value: 'api_key.update', label: intl('EventUtils.APIKeyUpdated')},
  {value: 'auth_security_principal.create', label: intl('EventUtils.RBACAuthSecPrincipalCreated')},
  {value: 'auth_security_principal.delete', label: intl('EventUtils.RBACAuthSecPrincipalDeleted')},
  {value: 'auth_security_principal.update', label: intl('EventUtils.RBACAuthSecPrincipalUpdated')},
  {value: 'authentication_settings.update', label: intl('EventUtils.AuthenticationSettingsUpdated')},
  {value: 'cluster.create', label: intl('EventUtils.ClusterCreated')},
  {value: 'cluster.delete', label: intl('EventUtils.ClusterDeleted')},
  {value: 'cluster.update', label: intl('EventUtils.ClusterUpdated')},
  {value: 'container_cluster.update', label: intl('EventUtils.ContainerClusterUpdated')},
  {value: 'container_cluster.update_services', label: intl('EventUtils.ContainerWorkloadUpdateServices')},
  {value: 'container_cluster.update_label_map', label: intl('EventUtils.ContainerClusterLabelMapUpdated')},
  {value: 'container_cluster.kubernetes_workloads_bulk_create', label: intl('EventUtils.KubernetesWorkloadsBulkCreated')},
  {value: 'container_cluster.kubernetes_workloads_bulk_delete', label: intl('EventUtils.KubernetesWorkloadsBulkDeleted')},
  {value: 'container_cluster.kubernetes_workloads_bulk_update', label: intl('EventUtils.KubernetesWorkloadsBulkUpdated')},
  {value: 'container_cluster.security_policy_acks', label: intl('EventUtils.ContainerClusterSecurityPolicyAcksUpdated')},
  {value: 'container_cluster.security_policy_applied', label: intl('EventUtils.ContainerClusterSecurityPolicyApplied')},
  {value: 'container_workload_profile.create', label: intl('EventUtils.ContainerWorkloadProfileCreated')},
  {value: 'container_workload_profile.delete', label: intl('EventUtils.ContainerWorkloadProfileDeleted')},
  {value: 'container_workload_profile.update', label: intl('EventUtils.ContainerWorkloadProfileUpdated')},
  {value: 'container_workload_profiles.update', label: intl('EventUtils.ContainerWorkloadProfilesUpdated')},
  {value: 'container_cluster.create', label: intl('EventUtils.ContainerClusterCreated')},
  {value: 'container_cluster.delete', label: intl('EventUtils.ContainerClusterDeleted')},
  {value: 'corporate_ips_setting.create', label: intl('EventUtils.CorporateIpsSettingsCreate')},
  {value: 'corporate_ips_setting.update', label: intl('EventUtils.CorporateIpsSettingsUpdate')},
  {value: 'corporate_ips_setting.delete', label: intl('EventUtils.CorporateIpsSettingsDelete')},
  {value: 'deny_rule.create', label: intl('EventUtils.DenyRuleCreated')},
  {value: 'deny_rule.delete', label: intl('EventUtils.DenyRuleDeleted')},
  {value: 'deny_rule.update', label: intl('EventUtils.DenyRuleUpdated')},
  {value: 'domain.create', label: intl('EventUtils.DomainCreated')},
  {value: 'domain.delete', label: intl('EventUtils.DomainDeleted')},
  {value: 'domain.update', label: intl('EventUtils.DomainUpdated')},
  {value: 'event_settings.update', label: intl('EventUtils.EventConfigUpdated')},
  {value: 'group.create', label: intl('EventUtils.CreateGroup')},
  {value: 'group.update', label: intl('EventUtils.ModifyGroup')},
  {value: 'group.delete', label: intl('EventUtils.DeleteGroup')},
  {value: 'pairing_profile.delete_all_pairing_keys', label: intl('EventUtils.DeletePairingProfileKeys')},
  {value: 'firewall_settings.update', label: intl('EventUtils.GlobalPolicySettingsUpdated')},
  {value: 'job.delete', label: intl('EventUtils.JobDeleted')},
  {value: 'ip_list.create', label: intl('EventUtils.IPListCreated')},
  {value: 'ip_list.delete', label: intl('EventUtils.IPListDeleted')},
  {value: 'ip_list.update', label: intl('EventUtils.IPListUpdated')},
  {value: 'ip_lists.delete', label: intl('EventUtils.IPListsDeleted')},
  {value: 'ip_tables_rule.create', label: intl('EventUtils.IPTablesRuleCreated')},
  {value: 'ip_tables_rule.delete', label: intl('EventUtils.IPTablesRuleDeleted')},
  {value: 'ip_tables_rule.update', label: intl('EventUtils.IPTablesRuleUpdated')},
  {value: 'label.create', label: intl('EventUtils.LabelCreated')},
  {value: 'label.delete', label: intl('EventUtils.LabelDeleted')},
  {value: 'label.update', label: intl('EventUtils.LabelUpdated')},
  {value: 'labels.delete', label: intl('EventUtils.LabelsDeleted')},
  {value: 'label_dimension.create', label: intl('EventUtils.LabelDimensionCreated')},
  {value: 'label_dimension.update', label: intl('EventUtils.LabelDimensionUpdated')},
  {value: 'label_dimension.delete', label: intl('EventUtils.LabelDimensionDeleted')},
  {value: 'label_group.create', label: intl('EventUtils.LabelGroupCreated')},
  {value: 'label_group.delete', label: intl('EventUtils.LabelGroupDeleted')},
  {value: 'label_group.update', label: intl('EventUtils.LabelGroupUpdated')},
  {value: 'label_mapping_rule.create', label: intl('EventUtils.LabelRuleCreated')},
  {value: 'label_mapping_rule.delete', label: intl('EventUtils.LabelRuleDeleted')},
  {value: 'label_mapping_rule.update', label: intl('EventUtils.LabelRuleUpdated')},
  {value: 'label_mapping_rules.delete', label: intl('EventUtils.LabelRulesDeleted')},
  {value: 'label_mapping_rule.reorder', label: intl('EventUtils.LabelRulesReordered')},
  {value: 'label_mapping_rules_run.assign_labels', label: intl('EventUtils.LabelRulesAsyncJobAssignedLabels')},
  {value: 'label_mapping_rules_run.create', label: intl('EventUtils.LabelRulesAsyncJobCreated')},
  {value: 'ldap_config.create', label: intl('EventUtils.LDAPConfigCreated')},
  {value: 'ldap_config.delete', label: intl('EventUtils.LDAPConfigDeleted')},
  {value: 'ldap_config.update', label: intl('EventUtils.LDAPConfigUpdated')},
  {value: 'ldap_config.verify_connection', label: intl('EventUtils.LDAPConfigVerifyConnection')},
  {value: 'license.delete', label: intl('EventUtils.LicenseDeleted')},
  {value: 'license.update', label: intl('EventUtils.LicenseUpdated')},
  {value: 'network.create', label: intl('EventUtils.NetworkCreated')},
  {value: 'network.update', label: intl('EventUtils.NetworkUpdated')},
  {value: 'network.delete', label: intl('EventUtils.NetworkDeleted')},
  {value: 'nfc.activate', label: intl('EventUtils.NetworkFuncCntlrActivated')},
  {value: 'nfc.deactivate', label: intl('EventUtils.NetworkFuncCntlrDeactivated')},
  {value: 'nfc.update_discovered_virtual_servers', label: intl('EventUtils.NetworkFuncCntlrDiscoveryComp')},
  {value: 'nfc.update_policy_status', label: intl('EventUtils.NetworkFuncCntlrPolicyStatusUpdated')},
  {value: 'nfc.update_slb_state', label: intl('EventUtils.NetworkFuncCntlrSLBStateUpdated')},
  {value: 'org.create', label: intl('EventUtils.OrgInfoCreated')},
  {value: 'org.recalc_rules', label: intl('EventUtils.AdminRecalc')},
  {value: 'org.remove_mssp_management', label: intl('EventUtils.OrgInfoUnmanaged')},
  {value: 'org.update', label: intl('EventUtils.OrgInfoUpdated')},
  {value: 'org_api_key.delete', label: intl('EventUtils.OrgApiKeyDelete')},
  {value: 'org_extract.create', label: intl('EventUtils.OrgExtractCreate')},
  {value: 'org_extract.update', label: intl('EventUtils.OrgExtractUpdate')},
  {value: 'org_extract.delete', label: intl('EventUtils.OrgExtractDelete')},
  {value: 'org_load.create', label: intl('EventUtils.OrgLoadCreate')},
  {value: 'org_load.update', label: intl('EventUtils.OrgLoadUpdate')},
  {value: 'org_load.delete', label: intl('EventUtils.OrgLoadDelete')},
  {value: 'pairing_profile.create', label: intl('EventUtils.PairingProfileCreated')},
  {value: 'pairing_profile.create_pairing_key', label: intl('EventUtils.PairingProfileKeyGenerated')},
  {value: 'pairing_profile.delete', label: intl('EventUtils.PairingProfileDeleted')},
  {value: 'pairing_profile.update', label: intl('EventUtils.PairingProfileUpdated')},
  {value: 'pairing_profiles.delete', label: intl('EventUtils.PairingProfilesDeleted')},
  {value: 'password_policy.create', label: intl('EventUtils.PasswordPolicyCreated')},
  {value: 'password_policy.delete', label: intl('EventUtils.PasswordPolicyDeleted')},
  {value: 'password_policy.update', label: intl('EventUtils.PasswordPolicyUpdated')},
  {value: 'pce.application_started', label: intl('PCE.Started')},
  {value: 'pce.application_stopped', label: intl('PCE.Stopped')},
  {value: 'permission.create', label: intl('EventUtils.RBACPermissionCreated')},
  {value: 'permission.delete', label: intl('EventUtils.RBACPermissionDeleted')},
  {value: 'permission.update', label: intl('EventUtils.RBACPermissionUpdated')},
  {value: 'radius.auth_challenge', label: intl('EventUtils.RADIUSAuthChallenge')},
  {value: 'radius_config.create', label: intl('EventUtils.RADIUSConfigsCreated')},
  {value: 'radius_config.delete', label: intl('EventUtils.RADIUSConfigDeleted')},
  {value: 'radius_config.update', label: intl('EventUtils.RADIUSConfigUpdated')},
  {value: 'radius_config.verify_shared_secret', label: intl('EventUtils.RADIUSConfigSecretVerified')},
  {value: 'resource.create', label: intl('EventUtils.ResourceCreated')},
  {value: 'resource.delete', label: intl('EventUtils.ResourceDeleted')},
  {value: 'resource.unmanage', label: intl('EventUtils.ResourceUnmanaged')},
  {value: 'resource.update', label: intl('EventUtils.ResourceUpdated')},
  {value: 'rule_set.create', label: intl('EventUtils.RulesetCreated')},
  {value: 'rule_set.delete', label: intl('EventUtils.RulesetDeleted')},
  {value: 'rule_set.update', label: intl('EventUtils.RulesetUpdated')},
  {value: 'rule_sets.delete', label: intl('EventUtils.RulesetsDeleted')},
  {value: 'saml_acs.update', label: intl('EventUtils.SAMLACSUpdated')},
  {value: 'saml_config.create', label: intl('EventUtils.SAMLConfigCreated')},
  {value: 'saml_config.delete', label: intl('EventUtils.SAMLConfigDeleted')},
  {value: 'saml_config.update', label: intl('EventUtils.SAMLConfigUpdated')},
  {value: 'saml_config.pce_signing_cert', label: intl('EventUtils.SAMLConfigPceSigningCert')},
  {value: 'saml_sp_config.create', label: intl('EventUtils.SAMLSPCreated')},
  {value: 'saml_sp_config.delete', label: intl('EventUtils.SAMLSPDeleted')},
  {value: 'saml_sp_config.update', label: intl('EventUtils.SAMLSPUpdated')},
  {value: 'sec_policy.create', label: intl('EventUtils.SecPoliciesCreated')},
  {value: 'sec_policy.restore', label: intl('EventUtils.SecPolicyRestored')},
  {value: 'sec_policy_pending.delete', label: intl('EventUtils.SecPolicyPendingDeleted')},
  {value: 'sec_rule.create', label: intl('EventUtils.SecPolicyRuleCreated')},
  {value: 'sec_rule.delete', label: intl('EventUtils.SecPolicyRuleDeleted')},
  {value: 'sec_rule.update', label: intl('EventUtils.SecPolicyRuleUpdated')},
  {value: 'sec_rules.delete', label: intl('EventUtils.SecPolicyRulesDeleted')},
  {value: 'secure_connect_gateway.create', label: intl('EventUtils.SecureConnectGatewayCreated')},
  {value: 'secure_connect_gateway.delete', label: intl('EventUtils.SecureConnectGatewayDeleted')},
  {value: 'secure_connect_gateway.update', label: intl('EventUtils.SecureConnectGatewayUpdated')},
  {value: 'security_principals.bulk_create', label: intl('EventUtils.SecPrincipalsBulkCreated')},
  {value: 'security_principal.create', label: intl('EventUtils.SecPrincipalsCreated')},
  {value: 'security_principal.delete', label: intl('EventUtils.SecPrincipalDeleted')},
  {value: 'security_principal.update', label: intl('EventUtils.SecPrincipalUpdated')},
  {value: 'service.create', label: intl('EventUtils.ServiceCreated')},
  {value: 'service.delete', label: intl('EventUtils.ServiceDeleted')},
  {value: 'service.update', label: intl('EventUtils.ServiceUpdated')},
  {value: 'services.delete', label: intl('EventUtils.ServicesDeleted')},
  {value: 'service_binding.create', label: intl('EventUtils.ServiceBindingCreated')},
  {value: 'service_binding.delete', label: intl('EventUtils.ServiceBindingDeleted')},
  {value: 'service_bindings.delete', label: intl('EventUtils.ServiceBindingsDeleted')},
  {value: 'service_account.create', label: intl('EventUtils.ServiceAccountCreated')},
  {value: 'service_account.delete', label: intl('EventUtils.ServiceAccountDeleted')},
  {value: 'service_account.update', label: intl('EventUtils.ServiceAccountUpdated')},
  {value: 'settings.update', label: intl('EventUtils.SettingsUpdate')},
  {value: 'setup_active_pce.create', label: intl('EventUtils.SetupActivePceCreate')},
  {value: 'setup_standby_pce.create', label: intl('EventUtils.SetupStandbyPceCreate')},
  {value: 'slb.create', label: intl('EventUtils.SLBCreated')},
  {value: 'slb.delete', label: intl('EventUtils.SLBDeleted')},
  {value: 'slb.update', label: intl('EventUtils.SLBUpdated')},
  {value: 'syslog_destination.create', label: intl('EventUtils.DestinationCreated')},
  {value: 'syslog_destination.delete', label: intl('EventUtils.DestinationDeleted')},
  {value: 'syslog_destination.update', label: intl('EventUtils.DestinationUpdated')},
  {value: 'system_task.agent_offline_check', label: intl('EventUtils.VENOfflineCheck')},
  {value: 'system_task.agent_missed_heartbeats_check', label: intl('EventUtils.VENMissedHeartbeatsCheck')},
  {value: 'system_task.agent_missing_heartbeats_after_upgrade', label: intl('EventUtils.VENMissingHeartbeatAfterUpgrade')},
  {value: 'system_task.agent_self_signed_certs_check', label: intl('EventUtils.VENSelfSignedCertsCheck')},
  {value: 'system_task.agent_settings_invalidation_error_state_check', label: intl('EventUtils.VENSettingsInvalidationErrorStateCheck')},
  {value: 'system_task.agent_uninstall_timeout', label: intl('EventUtils.VENUninstallTimeout')},
  {value: 'system_task.clear_auth_recovery_condition', label: intl('EventUtils.VENClearAuthRecoveryCondition')},
  {value: 'system_task.compute_policy_for_unmanaged_workloads', label: intl('EventUtils.ComputePolicyForUnmanagedWorkloads')},
  {value: 'system_task.delete_expired_service_account_api_keys', label: intl('EventUtils.DeleteExpireServiceAccountApiKeys')},
  {value: 'system_task.delete_old_cached_perspectives', label: intl('EventUtils.DeleteOldCachedPerspectives')},
  {value: 'system_task.endpoint_offline_check', label: intl('EventUtils.EndpointOfflineCheck')},
  {value: 'system_task.expire_service_account_api_keys', label: intl('EventUtils.ExpireServiceAccountApiKeys')},
  {value: 'system_task.provision_container_cluster_services', label: intl('EventUtils.ContainerClusterServicesProvisioned')},
  {value: 'system_task.prune_old_log_events', label: intl('EventUtils.EventPruningCompleted')},
  {value: 'system_task.remove_stale_zone_subnets', label: intl('EventUtils.RemoveStaleZoneSubnets')},
  {value: 'system_task.set_server_sync_check', label: intl('EventUtils.SetServerSyncCheck')},
  {value: 'system_task.vacuum_deactivated_agent_and_deleted_workloads', label: intl('EventUtils.VacuumDeactivatedAndDeletedWorkloads')},
  {value: 'tls_channel.establish', label: intl('EventUtils.TLSChannelEstablished')},
  {value: 'tls_channel.terminate', label: intl('EventUtils.TLSChannelTerminated')},
  {value: 'traffic_collector_setting.create', label: intl('EventUtils.TrafficCollectorSettingCreate')},
  {value: 'traffic_collector_setting.delete', label: intl('EventUtils.TrafficCollectorSettingDelete')},
  {value: 'traffic_collector_setting.update', label: intl('EventUtils.TrafficCollectorSettingUpdate')},
  {value: 'trusted_proxy_ips.update', label: intl('EventUtils.TrustedProxyIpsUpdated')},
  {value: 'user.accept_invitation', label: intl('EventUtils.UserAcceptInvitation')},
  {value: 'user.authenticate', label: intl('EventUtils.LocalUserAuthenticated')},
  {value: 'user.create', label: intl('EventUtils.UserCreated')},
  {value: 'user.create_session', label: intl('EventUtils.PCESessionCreated')},
  {value: 'user.delete', label: intl('EventUtils.UserDeleted')},
  {value: 'user.get_or_put', label: intl('EventUtils.UserSynced')},
  {value: 'user.invite', label: intl('EventUtils.UserInvited')},
  {value: 'user.login', label: intl('EventUtils.UserLogin')},
  {value: 'user.logout', label: intl('EventUtils.UserLogout')},
  {value: 'user.use_expired_password', label: intl('EventUtils.UserPasswordExpired')},
  {value: 'user.reset_password', label: intl('EventUtils.UserResetPassword')},
  {value: 'user.sign_in', label: intl('EventUtils.UserSignIn')},
  {value: 'user.sign_out', label: intl('EventUtils.UserSignOut')},
  {value: 'user.update', label: intl('EventUtils.UserUpdated')},
  {value: 'user.update_password', label: intl('EventUtils.UserPasswordUpdated')},
  {value: 'user.verify_mfa', label: intl('EventUtils.UserVerifyMFA')},
  {value: 'users.auth_token', label: intl('EventUtils.UserAuthTokenCreated')},
  {value: 'user_local_profile.create', label: intl('EventUtils.LocalUserProfileCreated')},
  {value: 'user_local_profile.delete', label: intl('EventUtils.LocalUserProfileDeleted')},
  {value: 'user_local_profile.update_password', label: intl('EventUtils.LocalUserPasswordChanged')},
  {value: 'user_local_profile.reinvite', label: intl('EventUtils.LocalUserReinvited')},
  {value: 'user.inactive_local_users_prune_check', label: intl('EventUtils.UserInactiveLocalUsersPruneCheck')},
  {value: 'ven_software_release.delete', label: intl('EventUtils.VENReleaseDeleted')},
  {value: 'ven_software_release.deploy', label: intl('EventUtils.VENReleaseDeployed')},
  {value: 'ven_settings.update', label: intl('EventUtils.VENSettingsUpdate')},
  {value: 'virtual_server.create', label: intl('EventUtils.VirtualServersCreated')},
  {value: 'virtual_server.delete', label: intl('EventUtils.VirtualServerDeleted')},
  {value: 'virtual_server.update', label: intl('EventUtils.VirtualServerUpdated')},
  {value: 'virtual_service.create', label: intl('EventUtils.VirtualServiceCreated')},
  {value: 'virtual_service.delete', label: intl('EventUtils.VirtualServiceDeleted')},
  {value: 'virtual_service.update', label: intl('EventUtils.VirtualServiceUpdated')},
  {value: 'virtual_services.bulk_create', label: intl('EventUtils.VirtualServicesCreated')},
  {value: 'virtual_services.bulk_update', label: intl('EventUtils.VirtualServicesUpdated')},
  {value: 'vulnerability.delete', label: intl('EventUtils.VulnerabilityDeleted')},
  {value: 'vulnerability.update', label: intl('EventUtils.VulnerabilityUpdated')},
  {value: 'vulnerability.create', label: intl('EventUtils.VulnerabilityCreated')},
  {value: 'vulnerability_report.delete', label: intl('EventUtils.VulnerabilityReportDeleted')},
  {value: 'vulnerability_report.update', label: intl('EventUtils.VulnerabilityReportUpdated')},
  {value: 'workload.create', label: intl('Workloads.Created')},
  {value: 'workload.delete', label: intl('Workloads.Deleted')},
  {value: 'workload.recalc_rules', label: intl('EventUtils.WorkloadPolicyRecalc')},
  {value: 'workload.redetect_network', label: intl('EventUtils.WorkloadNetworkRedetect')},
  {value: 'workload.update', label: intl('EventUtils.WorkloadUpdated')},
  {value: 'workload.online', label: intl('EventUtils.WorkloadOnline')},
  {value: 'workload.offline_after_ven_goodbye', label: intl('EventUtils.WorkloadOfflineVenGoodbye')},
  {value: 'workload.duplicate_interface_reported', label: intl('EventUtils.WorkloadDuplicateInterfaceReported')},
  {value: 'workloads.apply_policy', label: intl('EventUtils.WorkloadApplyPolicy')},
  {value: 'workloads.bulk_create', label: intl('EventUtils.WorkloadsBulkCreated')},
  {value: 'workloads.bulk_delete', label: intl('EventUtils.WorkloadsBulkDeleted')},
  {value: 'workloads.bulk_import', label: intl('EventUtils.WorkloadsBulkImported')},
  {value: 'workloads.bulk_update', label: intl('EventUtils.WorkloadsBulkUpdated')},
  {value: 'workloads.remove_labels', label: intl('EventUtils.WorkloadsLabelsRemoved')},
  {value: 'workloads.set_flow_reporting_frequency', label: intl('EventUtils.WorkloadFlowReportingFreq')},
  {value: 'workloads.set_labels', label: intl('EventUtils.WorkloadLabelsApplied')},
  {value: 'workloads.unpair', label: intl('EventUtils.WorkloadsUnpaired')},
  {value: 'workload_interface.create', label: intl('EventUtils.WorkloadInterfacesCreated')},
  {value: 'workload_interface.delete', label: intl('EventUtils.WorkloadInterfaceDeleted')},
  {value: 'workload_interfaces.delete', label: intl('EventUtils.WorkloadInterfacesDeleted')},
  {value: 'workload_interfaces.update', label: intl('EventUtils.WorkloadInterfacesUpdated')},
  {value: 'workload_service_report.update', label: intl('EventUtils.WorkloadServiceReportsUpdated')},
  {value: 'workload_settings.update', label: intl('EventUtils.WorkloadSettingsUpdated')},
  {value: 'workloads.update', label: intl('EventUtils.WorkloadsUpdated')},
  {value: 'user.pce_session_terminated', label: intl('EventUtils.PCESessionTerminated')},
  {value: 'user.login_session_terminated', label: intl('EventUtils.LoginUserSessionTerminated')},
  {value: 'request.unknown_server_error', label: intl('EventUtils.RequestUnknownServer')},
  {value: 'request.service_unavailable', label: intl('EventUtils.RequestServiceUnavailable')},
  {value: 'request.internal_server_error', label: intl('EventUtils.RequestInternalServer')},
  {value: 'request.authentication_failed', label: intl('EventUtils.RequestAuthenticationFailed')},
  {value: 'request.authorization_failed', label: intl('EventUtils.RequestAuthorizationFailed')},
  {value: 'remote_syslog.reachable', label: intl('EventUtils.RemoteSyslogReachable')},
  {value: 'remote_syslog.unreachable', label: intl('EventUtils.RemoteSyslogUnreachable')},
  {value: 'request.invalid', label: intl('EventUtils.RequestInvalid')},
  {value: 'network_device.ack_enforcement_instructions_applied', label: intl('EventUtils.NetworkDeviceAckEnforcementInstructionsApplied')},
  {value: 'network_device.assign_workload', label: intl('EventUtils.NetworkDeviceAssignWorkload')},
  {value: 'network_device.update_workload', label: intl('EventUtils.NetworkDeviceUpdateWorkload')},
  {value: 'network_device.delete_workload', label: intl('EventUtils.NetworkDeviceDeleteWorkload')},
  {value: 'network_device.create', label: intl('EventUtils.NetworkDeviceCreate')},
  {value: 'network_device.delete', label: intl('EventUtils.NetworkDeviceDelete')},
  {value: 'network_device.update', label: intl('EventUtils.NetworkDeviceUpdate')},
  {value: 'network_device.workloads_bulk_create', label: intl('EventUtils.NetworkDeviceWorkloadsBulkCreate')},
  {value: 'network_device.workloads_bulk_update', label: intl('EventUtils.NetworkDeviceWorkloadsBulkUpdate')},
  {value: 'network_devices.ack_multi_enforcement_instructions_applied', label: intl('EventUtils.NetworkDevicesAckMultiEnforcementInstructionsApplied')},
  {value: 'network_endpoint.create', label: intl('EventUtils.NetworkEndpointCreate')},
  {value: 'network_endpoint.delete', label: intl('EventUtils.NetworkEndpointDeleted')},
  {value: 'network_endpoint.update', label: intl('EventUtils.NetworkEndpointUpdate')},
  {value: 'network_enforcement_node.activate', label: intl('EventUtils.NetworkEnforcementNodeActivate')},
  {value: 'network_enforcement_node.request_policy', label: intl('EventUtils.NetworkEnforcementNodeRequestPolicy')},
  {value: 'network_enforcement_node.deactivate', label: intl('EventUtils.NetworkEnforcementNodeDeactivated')},
  {value: 'network_enforcement_node.policy_ack', label: intl('EventUtils.NetworkEnforcementNodePolicyAck')},
  {value: 'network_enforcement_node.update', label: intl('EventUtils.NetworkEnforcementNodeUpdate')},
  {value: 'database.temp_table_autocleanup_completed', label: intl('EventUtils.TempTableAutocleanupCompleted')},
  {value: 'database.temp_table_autocleanup_started', label: intl('EventUtils.TempTableAutocleanupStartup')},
  {value: 'enforcement_boundary.create', label: intl('EventUtils.DenyRuleCreated')},
  {value: 'enforcement_boundary.update', label: intl('EventUtils.DenyRuleUpdated')},
  {value: 'enforcement_boundary.delete', label: intl('EventUtils.DenyRuleDeleted')},
  {value: 'enforcement_point.create', label: intl('EventUtils.EnforcementPointCreate')},
  {value: 'enforcement_point.delete', label: intl('EventUtils.EnforcementPointDelete')},
  {value: 'enforcement_point.update', label: intl('EventUtils.EnforcementPointUpdate')},
  {value: 'enforcement_points.set', label: intl('EventUtils.EnforcementPointsSet')},
  {value: 'network_enforcement_node.missed_heartbeats_check', label: intl('EventUtils.NetworkEnforcementNodeMissedHeartBeatsCheck')},
  {value: 'network_enforcement_nodes.clear_conditions', label: intl('EventUtils.NetworkEnforcementNodeClearConditions')},
  {value: 'essential_service_rules.update', label: intl('EventUtils.EssentialServiceRulesUpdated')},
  {value: 'container_cluster.change_rate', label: intl('EventUtils.ContainerClusterChangeRate')},
  {value: 'login_proxy_msp_tenants.create', label: intl('EventUtils.LoginMSPTenantCreated')},
  {value: 'login_proxy_msp_tenants.delete', label: intl('EventUtils.LoginMSPTenantDeleted')},
  {value: 'login_proxy_msp_tenants.update', label: intl('EventUtils.LoginMSPTenantUpdated')},
  {value: 'login_proxy_orgs.create', label: intl('EventUtils.LoginMSPTenantCreated')},
  {value: 'login_proxy_orgs.delete', label: intl('EventUtils.LoginMSPTenantDeleted')},
  {value: 'login_proxy_orgs.update', label: intl('EventUtils.LoginMSPTenantUpdated')},
  {value: 'org.unpair_vens', label: intl('EventUtils.OrgUnpairVENs')},
  {value: 'orgs.ven_count', label: intl('EventUtils.OrgsVENCount')},
  {value: 'protection_schema.create', label: intl('EventUtils.ProtectionSchemaCreated')},
  {value: 'protection_schema.delete', label: intl('EventUtils.ProtectionSchemaDeleted')},
  {value: 'protection_schema.update', label: intl('EventUtils.ProtectionSchemaUpdated')},
  {value: 'protection_schemas.groupings', label: intl('EventUtils.ProtectionSchemaGroupingsCreated')},
];

export const NotificationTypes = [
  {value: 'agent.missing_heartbeats_after_upgrade', label: intl('EventUtils.VENMissingHeartbeatAfterUpgrade')},
  {value: 'agent.maintenance_token_requested', label: intl('EventUtils.VENMaintenanceTokenRequested')},
  {value: 'agent.upgrade_requested', label: intl('EventUtils.VENUpgradeRequested')},
  {value: 'agent.upgrade_successful', label: intl('EventUtils.VENUpgradeSuccessful')},
  {value: 'agent.upgrade_time_out', label: intl('EventUtils.VENUpgradeTimeOut')},
  {value: 'lost_agent.found', label: intl('EventUtils.LostAgentFound')},

  {value: 'agent.authentication_recovery', label: intl('EventUtils.VENAuthenticationMode')},
  {value: 'agent.self_signed_certificate_expired', label: intl('EventUtils.VENSelfSignedExpired')},
  {value: 'agent.self_signed_certificate_in_grace_period', label: intl('EventUtils.VENSelfSignedGracePeriod')},
  {value: 'agent.unauthenticated_clone_detected', label: intl('EventUtils.VENUnauthenticatedClone')},
  {value: 'agent.weak_tls_ciphers_used', label: intl('EventUtils.VENWeakTLSCipherDetected')},
  {value: 'agent.clone_detected', label: intl('EventUtils.VENCloneDetected')},
  {value: 'agent.process_failed', label: intl('EventUtils.VENProcessFailure')},
  {value: 'agent.missed_heartbeats', label: intl('EventUtils.VENMissedHeartbeatsCheck')},
  {value: 'agent.service_not_available', label: intl('EventUtils.VENServiceUnavailable')},
  {value: 'agent.policy_deploy_failed', label: intl('EventUtils.VENPolicyDeployFailed')},
  {value: 'agent.policy_deploy_succeeded', label: intl('EventUtils.VENPolicyDeployedSuccessfully')},
  {value: 'agent.fw_state_table_threshold_exceeded', label: intl('EventUtils.VENFirewallStateTableThresholdExceeded')},
  {value: 'agent.too_many_interfaces_reported', label: intl('EventUtils.VENHasTooManyInterfacesReported')},
  {value: 'container_cluster.duplicate_machine_id', label: intl('EventUtils.ContainerClusterDuplicateMachineID')},
  {value: 'container_cluster.region_mismatch', label: intl('EventUtils.ContainerClusterRegionMismatch')},
  {value: 'container_workload.invalid_pairing_config', label: intl('EventUtils.ContainerWorkloadInvalidPairingConfig')},
  {value: 'container_workload.not_created', label: intl('EventUtils.ContainerWorkloadNotCreated')},
  {value: 'database.temp_table_autocleanup_completed', label: intl('EventUtils.DatabaseCleanupStarted')},
  {value: 'database.temp_table_autocleanup_started', label: intl('EventUtils.DatabaseCleanupCompleted')},
  {value: 'request.invalid', label: intl('EventUtils.RequestInvalid')},
  {value: 'hard_limit.exceeded', label: intl('EventUtils.HardLimitExceeded')},
  {value: 'org.update', label: intl('EventUtils.OrgInfoUpdated')},
  {value: 'pce.application_started', label: intl('PCE.Started')},
  {value: 'pce.application_stopped', label: intl('PCE.Stopped')},
  {value: 'remote_syslog.reachable', label: intl('EventUtils.RemoteSyslogReachable')},
  {value: 'remote_syslog.unreachable', label: intl('EventUtils.RemoteSyslogUnreachable')},
  {value: 'request.unknown_server_error', label: intl('EventUtils.RequestUnknownServer')},
  {value: 'request.service_unavailable', label: intl('EventUtils.RequestServiceUnavailable')},
  {value: 'request.internal_server_error', label: intl('EventUtils.RequestInternalServer')},
  {value: 'request.authentication_failed', label: intl('EventUtils.RequestAuthenticationFailed')},
  {value: 'request.authorization_failed', label: intl('EventUtils.RequestAuthorizationFailed')},
  {value: 'request.invalid', label: intl('EventUtils.RequestInvalid')},
  {value: 'sec_policy.restored', label: intl('EventUtils.SecPolicyRestoredNotification')},
  {value: 'soft_limit.exceeded', label: intl('EventUtils.SoftLimitExceeded')},
  {value: 'system_task.event_pruning_completed', label: intl('EventUtils.EventPruningCompleted')},
  {value: 'system_task.hard_limit_recovery_completed', label: intl('EventUtils.HardLimitRecoveryCompleted')},
  {value: 'user.csrf_validation_failed', label: intl('EventUtils.UserCSRFValidationFailed')},
  {value: 'user.login_failed', label: intl('EventUtils.UserLoginFailed')},
  {value: 'user.login_failure_count_exceeded', label: intl('Users.LoginFailedCountExceeded')},
  {value: 'user.login_session_created', label: intl('EventUtils.LoginUserSessionCreated')},
  {value: 'user.login_session_terminated', label: intl('EventUtils.LoginUserSessionTerminated')},
  {value: 'user.pce_session_created', label: intl('EventUtils.PCESessionCreated')},
  {value: 'user.pce_session_terminated', label: intl('EventUtils.PCESessionTerminated')},
  {value: 'user.pw_changed', label: intl('EventUtils.UserPasswordChanged')},
  {value: 'user.pw_change_failure', label: intl('EventUtils.UserPasswordChangeFailure')},
  {value: 'user.pw_complexity_not_met', label: intl('EventUtils.UserPasswordComplexityNotMet')},
  {value: 'user.pw_reset_completed', label: intl('EventUtils.UserPasswordResetCompleted')},
  {value: 'user.pw_reset_requested', label: intl('EventUtils.UserPasswordResetRequested')},
  {value: 'local_user.deleted_for_inactivity', label: intl('EventUtils.LocalUserDeletedForInactivity')},
  {value: 'local_user.unable_to_delete_for_inactivity', label: intl('EventUtils.LocalUserUnableToDeleteForInactivity')},
  {value: 'virtual_service.not_created', label: intl('EventUtils.VirtualServiceNotCreated')},
  {value: 'workload.duplicate_interface_reported', label: intl('EventUtils.WorkloadDuplicateInterfaceReported')},
  {value: 'workload.partial_policy_delivered', label: intl('EventUtils.WorkloadPartialPolicyDelivered')},
  {value: 'workload.oob_policy_changes', label: intl('EventUtils.FirewallTampered')},
  {value: 'workload.update_mismatched_interfaces', label: intl('EventUtils.WorkloadMismatchedInterfaces')},
  {value: 'workload.online', label: intl('EventUtils.WorkloadOnline')},
  {value: 'workloads.flow_reporting_frequency_updated', label: intl('EventUtils.WorkloadFlowReportingFrequencyUpdated')},
  {value: 'workload.nat_rules_present', label: intl('EventUtils.WorkloadNatRulesPresent')},
  {value: 'workload.offline_after_ven_goodbye', label: intl('EventUtils.WorkloadOfflineVenGoodbye')},
  {value: 'database.temp_table_autocleanup_completed', label: intl('EventUtils.TempTableAutocleanupCompleted')},
  {value: 'database.temp_table_autocleanup_started', label: intl('EventUtils.TempTableAutocleanupStartup')},
  {value: 'network_enforcement_node.degraded', label: intl('EventUtils.NetworkEnforcementNodeDegraded')},
  {value: 'network_enforcement_node.missed_heartbeats', label: intl('EventUtils.NetworkEnforcementNodeMissedHeartBeats')},
  {value: 'container_cluster.change_rate', label: intl('EventUtils.ContainerClusterChangeRate')},
] as Events<ReturnType<typeof intl>>[];

export const EventSeverity = createSelector([], () => [
  {value: 'err', label: intl('Common.Error')()},
  {value: 'warning', label: intl('Common.Warning')()},
  {value: 'info', label: intl('SystemSettings.LogForwarding.Severity.Info')()},
]);

export const eventStatus = createSelector([], () => [
  {value: 'success', label: intl('Common.Success')()},
  {value: 'failure', label: intl('Common.Failure')()},
  {value: 'nil', label: intl('Common.NA')()},
]);

export const getSortedEventTypes = createSelector([], () => {
  const allEvents = [...EventTypes];

  return allEvents.sort((a, b) => a.label().localeCompare(b.label()));
});

export const getEventType = (type: string, isEventOnly: boolean): string | null => {
  if (!type || !type.length) {
    return null;
  }

  let event = EventTypes.find(eventType => eventType.value === type);

  if (!isEventOnly) {
    event = NotificationTypes.find(eventType => eventType.value === type);
  }

  if (event) {
    return event.label();
  }

  return _.capitalize(type.split(/[._]+/).join(' '));
};

export const getEventSeverity = (severity: string): string | null => {
  /** Use Double Assertion to let Typescript know that using _.find() expect to get Events type. */
  const event = _.find(EventSeverity({} as never), eventSeverity => eventSeverity.value === severity);

  return event?.label || null;
};

export const getEventStatusLabel = (status: string): string => {
  const statusValue = _.find(eventStatus({} as never), item => item.value === status);

  return statusValue?.label || '';
};

export const getEventGeneratedBy = (value: EventGeneratedBy): string => {
  if (value?.server) {
    return value.server.hostname;
  }

  if (value?.user) {
    return value.user.username;
  }

  if (value?.service_account) {
    return value.service_account.name;
  }

  if (value?.system !== undefined) {
    return intl('Common.System')();
  }

  if (value?.console !== undefined) {
    return intl('Common.Console')();
  }

  // EYE-91078: New events use the ven property while old events still look for agent property to retrieve hostname.
  if (value?.ven || value?.agent) {
    return value.ven?.hostname ?? value.agent?.hostname ?? intl('EventUtils.VENHostUnknown')();
  }

  if (value?.container_cluster) {
    return value.container_cluster.name;
  }

  return '';
};

export const validUUID = (uuid: string): RegExpMatchArray | null =>
  uuid.match(/^[\da-f]{8}-[\da-f]{4}-[1-5][\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i);

const today = new Date();
const todayDate = today.toLocaleDateString();
const yesterday = new Date();

yesterday.setDate(yesterday.getDate() - 1);

const yesterdayDate = yesterday.toLocaleDateString();

/**
 * Use to return localization translation when date is either today or yesterday
 *
 * @param timestamp
 * @returns date
 */
export const getFilterFriendlyTimestamp = <T extends string | undefined = undefined>(timestamp?: T): T extends string ? string : undefined => {
  if (timestamp === undefined) {
    return undefined as T extends string ? string : undefined;
  }

  let date = '';

  if (typeof timestamp === 'string') {
    date = new Date(timestamp).toLocaleDateString();

    if (date === todayDate) {
      date = intl('Common.Today')();
    }

    if (date === yesterdayDate) {
      date = intl('Events.Yesterday')();
    }
  }

  return date as T extends string ? string : undefined;
};

export const getTimestampFromString = (value: string): Date => {
  if (value === intl('Common.Today')()) {
    return today;
  }

  if (value === intl('Events.Yesterday')()) {
    return yesterday;
  }

  return new Date(value);
};
